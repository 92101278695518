<template>
	<div style="height: 100vh">
		<div
				style="width: calc(100% - 768px);
				height: 100%; float: left;
				background-color: #F6F6FA;
				display: flex;
				align-items: center"
		>
			<v-img :src="backgroundUrl" :lazy-src="backgroundUrl" width="100%" height="100%"/>
		</div>
		<div style="width: 768px; height: 100%; float: right; background-color: #ffffff;">
			<div style="margin:30px 80px 0 80px">
				<v-img :src="systemLogo" height="40" width="200"></v-img>
				<div class="login-title">{{ title }}</div>
				<div class="sub-title">{{ subTitle }}</div>
				<v-form ref="form" v-model="valid">
					<v-text-field
							ref="labelRef"
							label="请输入项目代码"
							v-model="form.projectid"
							:rules="checkRules('请输入项目代码')"
							prepend-inner-icon="iconfont icon-xiangmu-fill"
							color="primary"
					></v-text-field>
					<v-text-field
							ref="labelRef"
							label="请输入账号"
							v-model="form.userid"
							:rules="checkRules('请输入账号')"
							prepend-inner-icon="iconfont icon-wode-fill"
							color="primary"
					></v-text-field>
					<v-text-field
							prepend-inner-icon="iconfont icon-mima-fill"
							label="请输入密码"
							v-model="form.password"
							:rules="checkRules('请输入密码')"
							:type="showPassword ? 'text' : 'password'"
							:append-icon="showPassword ? 'iconfont icon-yincangmima-fill' : 'iconfont icon-xianshimima-fill'"
							@click:append="showPassword = !showPassword"
							color="primary"
							autocomplete="off"
							readonly
							onfocus="this.removeAttribute('readonly')"
					>
					</v-text-field>
					<div>
						<v-checkbox
								v-model="checkbox"
								label="记住账号密码"
								color="primary"
						></v-checkbox>
					</div>
					<div style="text-align: center">
						<v-btn
								:loading="btnLoading"
								:width="btnWidth"
								depressed
								style="margin-top: 20px"
								color="primary"
								@click="confirm"
						>
							登录
						</v-btn>
					</div>
				</v-form>
			</div>
		</div>
		<div style="clear: both;"></div>
	</div>
</template>

<script>
import api from "@/api/login.js";
import commonSetting from '@/mixin/common-setting.js';
import menuPermissions from "@/mixin/menu-permissions.js";
import {encrypt} from '@/utils/aseUtils'

export default {
	name: "login",
	mixins: [commonSetting, menuPermissions],
	data() {
		return {
			valid: true,
			form: {
				userid: "",
				password: "",
				encryptPwd: "",
				projectid: ''
			},
			// 记住账号密码状态
			checkbox: false,
			showPassword: false,
			btnWidth: '',
			btnLoading: false,
			// 通用设置
			title: '景区一体化平台',
			subTitle: 'welcome to digital scenic spot',
			backgroundUrl: require('@/assets/login.png'),
			systemLogo: require('@/assets/logo/system-logo.svg'),
			// systemLogo: ''
		}
	},
	methods: {
		checkRules(text) {
			return [(v) => v !== "" || text];
		},
		confirm() {
			let validate = this.$refs.form.validate();
			if (validate) {
				this.btnLoading = true;
				let form = JSON.parse(JSON.stringify(this.form))
				form.encryptPwd = encrypt(form.password)
				delete form.password
				this.axios.post(api.login, form).then((res) => {
					this.btnLoading = false;
					if (this.staticVal.Code.Success === res.code) {
						if (this.checkbox) {
							localStorage.setItem("projectid", this.form.projectid);
							localStorage.setItem("userid", this.form.userid);
							localStorage.setItem("password", this.form.password);
						} else {
							localStorage.removeItem("projectid");
							localStorage.removeItem("userid");
							localStorage.removeItem("password");
						}
						this.snackbar.success('登录成功！');
						this.$store.commit('setToken', res.data.token);
						this.$store.commit('setUserId', this.form.userid);
						localStorage.removeItem('common-setting');
						// 登录成功获取系统设置信息
						this.loadCommonSetting(false);
						this.getMenuPermissions((index) => {
							this.$router.push(index ? '/index' : '/blank');
						}, true);
					}
				});
			}
		},
	},
	created() {
		let projectid = localStorage.getItem("projectid");
		let userid = localStorage.getItem("userid");
		let password = localStorage.getItem("password");
		if (userid && password && projectid) {
			Object.assign(this.form, {projectid, userid, password});
			this.checkbox = true;
			// 获取通用设置
			this.loadCommonSetting(true);
		}
	},
	mounted() {
		this.btnWidth = this.$refs.labelRef.$el.clientWidth;
		// 应用系统主题
		let color = localStorage.getItem("themeColorKey");
		if (color) {
			this.$vuetify.theme.themes.light.primary = color;
		}
		// 回车键登录
		let _this = this;
		document.onkeydown = function (e) {
			let ev = document.all ? window.event : e
			if (ev.keyCode === 13) {
				_this.confirm();
			}
		}
	}
}
</script>

<style scoped lang="scss">

.login-title {
	color: var(--v-primary-base);
	margin-top: 120px;
	font-size: 40px;
	font-weight: bold;
}

.sub-title {
	color: var(--v-primary-base);
	margin-bottom: 74px;;
	font-size: 24px;
}

::v-deep {
	.v-text-field__slot {
		margin-left: 10px;
	}

	.icon-wode-fill:before, .icon-mima-fill:before, .icon-xiangmu-fill::before {
		color: var(--v-primary-base);
		font-size: 24px;
	}

	.icon-xianshimima-fill:before, .icon-yincangmima-fill:before {
		color: var(--v-primary-base);
		font-size: 24px;
	}

	.v-label {
		font-size: 18px !important;
	}

	.v-input--checkbox {
		.v-label {
			font-size: 16px !important;
			color: #666666 !important;
		}
	}

	.v-text-field input {
		font-size: 16px;
	}

	.v-btn:not(.v-btn--round).v-size--default {
		height: 48px !important;
	}

	.v-btn.v-size--default {
		font-size: 18px !important;
	}

	.v-text-field .v-label--active {
		display: none;
	}
}
</style>
