import CryptoJS from "crypto-js"

export function encrypt(word) {
  let key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5('CITYBAYTECH').toString().substring(0, 16))
  let res = CryptoJS.enc.Utf8.parse(word)
  let encrypted = CryptoJS.AES.encrypt(res, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted.toString()))
}

